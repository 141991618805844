import { useFormStore } from '@/store/forms.store'

import { scrollTo } from '@/utils/helpers'
import type { HubSpotFormId } from '@/types'

interface Options {
  headers?: {}
  headerOptions?: {
    headers?: {}
  }
  thankYouRoute?: string
  formSelector?: string
  offset?: number
}

// Define allowed domains and paths as constants
const ALLOWED_DOMAINS = {
  HUBSPOT_API: 'api.hsforms.com',
  HUBSPOT_FORMS: 'forms.hscollectedforms.net',
  GOOGLE_ANALYTICS: 'www.google-analytics.com',
} as const

const ALLOWED_PATHS = {
  HUBSPOT_SUBMIT: '/submissions/v3/integration/submit/5731513/',
  HUBSPOT_COLLECT: '/collected-forms/submit/form',
  GOOGLE_ANALYTICS: '/collect',
} as const

// Internal API endpoints
const INTERNAL_API_PATHS = {
  HUBSPOT_PROXY: '/api/hubspot-proxy',
} as const

// Regular expression for valid UUID v4 format (used for form IDs)
const UUID_V4_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/

/**
 * Validate if a URL is allowed based on our security rules
 * @param urlString The URL to validate
 * @returns boolean indicating if the URL is allowed
 */
const isValidUrl = (urlString: string): boolean => {
  try {
    // For absolute URLs (starting with http or https)
    if (urlString.startsWith('http')) {
      const url = new URL(urlString)

      // Only allow HTTPS protocol
      if (url.protocol !== 'https:') {
        return false
      }

      // Check against allowed domains and paths combinations
      if (
        // HubSpot API submission URL
        (url.hostname === ALLOWED_DOMAINS.HUBSPOT_API &&
          url.pathname.startsWith(ALLOWED_PATHS.HUBSPOT_SUBMIT) &&
          UUID_V4_REGEX.test(
            url.pathname.substring(ALLOWED_PATHS.HUBSPOT_SUBMIT.length),
          )) ||
        // HubSpot form collector URL
        (url.hostname === ALLOWED_DOMAINS.HUBSPOT_FORMS &&
          url.pathname === ALLOWED_PATHS.HUBSPOT_COLLECT) ||
        // Google Analytics URL
        (url.hostname === ALLOWED_DOMAINS.GOOGLE_ANALYTICS &&
          url.pathname === ALLOWED_PATHS.GOOGLE_ANALYTICS)
      ) {
        return true
      }

      return false
    }
    // For relative URLs (internal API endpoints)
    else if (urlString.startsWith('/')) {
      // For HubSpot proxy endpoint
      if (urlString.startsWith(INTERNAL_API_PATHS.HUBSPOT_PROXY)) {
        const queryStartIndex = urlString.indexOf('?')
        if (queryStartIndex === -1) return false

        const queryParams = new URLSearchParams(
          urlString.substring(queryStartIndex),
        )
        const formId = queryParams.get('formId')

        // Validate formId is a valid UUID v4
        return !!formId && UUID_V4_REGEX.test(formId)
      }

      return false
    }

    // All other URL formats are disallowed
    return false
  } catch (e) {
    // If URL parsing fails, consider it invalid
    console.error('URL validation error:', e)
    return false
  }
}

const getGenericFormUrl = (id: HubSpotFormId) => {
  return `${INTERNAL_API_PATHS.HUBSPOT_PROXY}?formId=${id}`
}

const useSendData = () => {
  const router = useRouter()
  const formStore = useFormStore()

  const isLoading = ref(false)
  const isFailed = ref(false)
  const isSuccessfullySent = ref(false)

  let urlParams: URLSearchParams | null = null

  onMounted(() => {
    urlParams = new URLSearchParams(window.location.search)
  })

  const onSuccess = (thankYouRoute: string) => {
    if (thankYouRoute) {
      router.push(thankYouRoute)
    }
    isSuccessfullySent.value = true
    isLoading.value = false
  }
  const onError = () => {
    isLoading.value = false
    isFailed.value = true
  }

  const scrollToError = (formSelector = '#form-wrapper', offset = 100) => {
    const errorElement = document.getElementsByClassName('input--error')[0]
    if (errorElement) {
      scrollTo('.input--error', 100)
      return
    }
    isFailed.value = true
    scrollTo(formSelector, offset)
  }

  const defaultHeaders = {
    'Content-Type': 'application/json; charset=UTF-8',
  }

  const sendFormData = async (
    url = '',
    formData = {},
    options: Options = {
      thankYouRoute: '/thank-you',
      formSelector: '#form-section',
      offset: 0,
    },
  ) => {
    isLoading.value = true

    try {
      // URL validation
      if (!isValidUrl(url)) {
        console.error(
          'Invalid URL detected. Request blocked for security reasons.',
        )
        onError()
        scrollToError(options.formSelector, options.offset)
        return null
      }

      const request = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(formData),
      })

      // Special handling for 204 No Content response (used by HubSpot form collector)
      if (request.status === 204) {
        formStore.SET_SUCCESS_MESSAGE('Form submitted successfully')
        onSuccess(options.thankYouRoute || '')
        return request
      }

      // For other responses that include JSON content
      try {
        const data = await request.json()

        if (request.ok) {
          formStore.SET_SUCCESS_MESSAGE(
            data.inlineMessage || 'Form submitted successfully',
          )
          onSuccess(options.thankYouRoute || '')
          return request
        } else {
          onError()
          scrollToError(options.formSelector, options.offset)
          return request
        }
      } catch (jsonError) {
        // If the response doesn't contain valid JSON but the request was successful
        if (request.ok) {
          formStore.SET_SUCCESS_MESSAGE('Form submitted successfully')
          onSuccess(options.thankYouRoute || '')
          return request
        } else {
          onError()
          scrollToError(options.formSelector, options.offset)
          console.error('Error parsing form response:', jsonError)
          return request
        }
      }
    } catch (error) {
      onError()
      scrollToError(options.formSelector, options.offset)
      console.error('Form submission error:', error)
      return null
    }
  }

  const sendGtmForm = async (
    url = '',
    formData = {},
    options: Options = {
      headerOptions: {
        headers: defaultHeaders,
      },
    },
  ) => {
    try {
      // URL validation - same validation as sendFormData
      if (!isValidUrl(url)) {
        console.error(
          'Invalid URL detected. Request blocked for security reasons.',
        )
        return null
      }

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          ...options.headerOptions?.headers,
        },
        body: JSON.stringify(formData),
      })

      // For Google Analytics and similar tracking pixels,
      // 204 No Content is a valid and expected response
      return response
    } catch (err) {
      if (err instanceof Error) {
        console.error('GTM form submission error:', err.message)
      }
      return null
    }
  }

  return {
    isLoading,
    isFailed,
    isSuccessfullySent,
    scrollToError,
    sendGtmForm,
    sendFormData,
    getGenericFormUrl,
  }
}
export default useSendData
